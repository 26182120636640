<template>
    <div>
        <multiselect
            :placeholder="$t('select')"
            v-model="selected"
            :options="options"
            :class="validateError != '' ? 'box-border-color' : ''"
            @input="handleInput($event, multiple)"
            label="text"
            track-by="value"
            :multiple="multiple"
            :close-on-select="!multiple"
            :clear-on-select="!multiple"
            :select-label="''"
            :selected-label="''"
            :deselect-label="''"
        >
            <template slot="selection" slot-scope="{ values, search, isOpen }" v-if="multiple">
		        <span class="multiselect__single" v-if="values.length && !isOpen">
			        {{ translateNSelected(values) }}
		        </span>
            </template>
            <span slot="noOptions">{{ $t('no_options') }}</span>
            <span slot="noResult">{{ $t('no_result') }}</span>
        </multiselect>
        <span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
    </div>
</template>

<script>
    // Helpers
    import translateNSelected from '@/helpers/translateNSelected';
    import handleInput from '@/helpers/handleInput';
    import setSelected from '@/helpers/setSelected';

    // Services
    import ParameterService from '@/services/ParameterService';

    // Other
    import qs from 'qs';

    export default {
        props: {
            code: {
                type: String,
                default: "document_request_types"
            },
            value: {
                default: null,
            },
            multiple: {
                default: false,
            },
            multipleReturnType: {
                default: 'array',
            },
            multipleShowCount: {
                default: 2,
            },
            validateError: {
                type: String,
                default: '',
            },
            sort: {
                type: String,
                default: 'name',
            },
        },
        watch: {
            value: function(newValue) {
                this.selected = this.setSelected(this.value, this.options, this.multiple, this.multipleReturnType);
            },
        },
        data() {
            return {
                selected: null,
                options: [],
            };
        },
        created() {
            this.getOptions();
        },
        methods: {
            translateNSelected: translateNSelected,
            setSelected: setSelected,
            handleInput: handleInput,
            getOptions() {
                const config = {
                    params: {
                        sort: this.sort,
                        limit: -1,
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                };

                this.options = [];
                ParameterService.getItems(this.code, config)
                                .then((response) => {
                                    const data = response.data.data;

                                    if (data && data.items) {
                                        data.items.map((item) => {
                                            this.options.push({
                                                value: item.code,
                                                text: this.getLocaleText(item, 'name'),
                                                data: item.data
                                            });
                                        });
                                    }
                                    this.$emit('returnOptions', this.options)
                                })
                                .then(() => {
                                    this.selected = this.setSelected(this.value, this.options, this.multiple,
                                        this.multipleReturnType);
                                });
            }
        },
    };
</script>
